import React, { useEffect, useRef, useState } from 'react';
import { Container, Group, Button, Text, Grid, Title, SimpleGrid, Card, ThemeIcon, Transition, Box, Image, List, Avatar, Rating, SegmentedControl, Accordion, ActionIcon, Badge } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'src/components/common/Icons/Icons/Icon';
import { CustomButton } from 'src/components/common/Buttons/CustomButton';
import useViewportHeight from 'src/hooks/useViewPortHeight';
import { authRoutes } from 'src/navigators/routes/auth';
import useResponsive from 'src/hooks/useResponsive';


const LandingPage: React.FC = () => {
  const navigate = useNavigate()
  const [currentSection, setCurrentSection] = useState(0);
  useViewportHeight();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const sections = [
    { id: 'hero', component: HeroSection, label: 'Home' },
    { id: 'why-choose-us', component: WhyChooseUsSection, label: 'Why Choose Us' },
    { id: 'learners', component: ForLearnersSection, label: 'Learners' },
    { id: 'parents', component: ForParentsSection, label: 'Parents' },
    { id: 'testimonials', component: TestimonialsSection, label: 'Testimonials' },
    { id: 'pricing', component: PricingSection, label: 'Pricing' },
    { id: 'faq', component: FAQSection, label: 'FAQ' },
  ];

  const scrollToSection = (index: number) => {
    const container = scrollContainerRef.current;
    if (container) {
      const sectionElement = container.children[index] as HTMLElement;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="h-screen w-screen">
      <header className="sticky top-0 w-full overflow-hidden bg-white z-10">
        <div className='hidden md:flex h-[10vh] flex-row items-center justify-between w-full px-8'>
          <div className='flex-1'></div>
          <div className='flex flex-row gap-8 items-center justify-center'>
            {sections.map((section, index) => (
              <button
                key={section.id}
                onClick={() => scrollToSection(index)}
                className="text-xs md:text-md lg:text-base cursor-pointer"
              >
                {section.label}
              </button>
            ))}
          </div>
          <div className='flex-1 flex justify-end'>
            <div className='flex flex-row gap-2 items-center'>
              <Text onClick={() => {
                window.open('https://wa.me/6597766164', '_blank');
              }} component='a' align="center" className="cursor-pointer hidden lg:block underline text-green-600 text-xs md:text-md lg:text-base">Whatsapp Us!</Text>
              <ActionIcon onClick={() => {
                window.open('https://wa.me/6597766164', '_blank');
              }}><Icon name='whatsapp' color='green' size={'20'} /></ActionIcon>
            </div>
          </div>
        </div>
        <div className='flex md:hidden h-[10vh] border-b items-center justify-between px-4'>
          <div className='flex-1'></div>
          <div className='flex flex-col items-center justify-center'>
            <Image className='max-w-28' src="/landing/logo.png" alt="Chat Interface" />
            <Text className='text-md md:text-lg text-center' color="dimmed">Join us!</Text>
          </div>
          <div className='flex-1 flex justify-end'>
            <div className='flex flex-row gap-2 items-center'>
            
              <ActionIcon onClick={() => {
                window.open('https://wa.me/6597766164', '_blank');
              }}><Icon name='whatsapp' color='green' size={'20'} /></ActionIcon>
            </div>
          </div>
        </div>
      </header>
      <div ref={scrollContainerRef} className='snap-y snap-mandatory h-[90vh] overflow-y-auto'>
        {sections.map(({ id, component: Section }, index) => (
          <section
            key={id}
            id={id}
            className="snap-center snap-always h-screen"
          >
            <Section />
          </section>
        ))}
        <div className='snap-start snap-always'><Footer /></div>
      </div>
    </div>
  );
};



const HeroSection: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className="h-4/5 md:h-full flex items-center justify-center">
      <div className="grid h-full w-full md:px-0 md:w-[85%] grid-rows-2 md:grid-rows-1 md:grid-cols-2">
        <div className="hidden md:flex md:col-span-1 order-last md:order-first">
          <div className="flex flex-col justify-center h-full w-full px-4 md:px-10">
            <div className="w-full flex-col justify-center items-center md:items-start md:justify-start my-2">
              <Image className="max-h-[80%] max-w-[80%]" src="/landing/logo.png" alt="Chat Interface" />
              <Text className='text-xs md:text-md lg:text-base' color="dimmed">Ready to create your 'Aha!' moments?</Text>
            </div>

            <div className="flex flex-row gap-4 h-16 items-center mb-4">
              <div className="h-10 w-10 flex items-center">
                <Image src="/landing/flag.png" alt="Chat Interface" />
              </div>
              <Text className="mx-4 text-xs md:text-md lg:text-base">Transforming how Singapore Learns</Text>
              {/* <div className="h-10 w-10 flex items-center self-start">
                <Image src="/landing/pencil.png" alt="Chat Interface" />
              </div> */}
            </div>

            <CustomButton onClick={() => {
                window.open('https://dev.myaiconsult.com/', '_blank');
            }} variant="default" size="md" width="w-full lg:w-3/4">
              <Text className='text-xs text-white font-semibold md:text-md lg:text-base'>Sign Up for your Free Access</Text>
            </CustomButton>
          </div>
        </div>
        <div className="row-span-2 md:col-span-1 flex flex-col items-center justify-center order-first md:order-last">
          <div className="w-full flex items-center justify-center">
            <Image
              className="max-h-[600px] max-w-[550px] object-contain"
              src="/landing/previewchatbot3.png"
              alt="Chat Interface"
            />
          </div>
          <div className='flex flex-col md:hidden gap-4 px-4 bg-white'>
            <div className="flex flex-row gap-4 items-center">
              <div className="h-10 w-10 flex items-center">
                <Image src="/landing/flag.png" alt="Chat Interface" />
              </div>
              <Text className="mx-4 text-xs md:text-md lg:text-base">Helping Singapore's young minds grow</Text>
              <div className="h-10 w-10 flex items-center self-start">
                <Image src="/landing/pencil.png" alt="Chat Interface" />
              </div>
            </div>

            <CustomButton variant="default" size="md" width="w-full lg:w-3/4">
              <Text className='text-sm md:text-md font-semibold text-white lg:text-base'>Get your Access now</Text>
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const WhyChooseUsSection: React.FC = () => (
  <div className="bg-gradient-to-r from-orange-50 to-white min-h-full flex items-center justify-center">
    <div className="w-full  px-10 md:px-0  md:w-[85%] ">
      <Text align="center" className="mb-8 font-bold text-black text-lg md:text-xl lg:text-xxl">
        Why Choose Us?
      </Text>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {[
          {
            image: "/landing/SG2.png",
            alt: "Curriculum",
            title: "Aligned To MOE's Curriculum",
            description: "Content and how learners are guided through the system are aligned to MOE's curriculum and teaching methods.",
            bgColor: "bg-primary",
          },
          {
            image: "/landing/chatgroup.png",
            alt: "Personalized",
            title: "Personalised Guidance",
            description: "Our technology provides personalised guidance based on each learners' needs. The guidance methods are informed by extensive research on learning.",
            bgColor: "bg-teal-500",
          },
          {
            image: "/landing/adaptivesystem.png",
            alt: "Adaptive",
            title: "Adaptive System",
            description: "A first of its kind adaptive system that provides guidance by creating a learning path unique to each learners' interactions.",
            bgColor: "bg-green-600",
          },
        ].map((card, index) => (
          <div key={index} className={`${card.bgColor} shadow-sm flex flex-col md:flex-col items-center rounded-xl px-4 py-4 lg:py-0 xl:py-4 justify-center h-fit lg:h-96`} >
            <div className='flex flex-row lg:flex-col items-center w-full lg:h-3/4'>
              <div className='w-10 h-10 dsm:w-20 dsm:h-20 flex items-center justify-center mb-0 lg:mb-4 mr-4 lg:mr-0'>
                <img src={card.image} alt={card.alt} className="w-20 h-20 object-contain" />
              </div>
              <div className='flex-1 max-h-18 dsm:max-h-full overflow-hidden lg:text-center'>
                <Text weight={500} className="mb-2 lg:mb-4 text-white hidden dsm:block text-lg lg:text-xl font-bold">{card.title}</Text>
                <Text className="text-white text-xs md:text-base">
                  {card.description}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const ForLearnersSection: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-gradient-to-r from-orange-50 to-white h-full flex items-center justify-center">
      <div className="w-full px-10 md:px-0 md:w-[85%] grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="hidden lg:flex items-center justify-center order-last md:order-first">
          <Image
            src="/landing/learnerCollage.png"
            alt="Learners using MYAi"
            className="max-h-[500px] max-w-[500px] object-contain"
          />
        </div>
        <div className="flex flex-col justify-center">
          <Text size="sm" color="dimmed" className="mb-2">For Learners</Text>
          <Title order={2} className="mb-4 text-lg md:text-xl lg:text-xxl">
            Turning "I don't know" to "Aha" moments
          </Title>
          <Text className="mb-6 text-xs md:text-md lg:text-base">
            With MYAi, you can ask questions and be guided one step at a time. Imagine having someone who can guide you anytime you need it!
          </Text>
          <Card shadow="sm" padding="md" radius="md" className="mb-4">
            <Group>
              <div>
                <Text className='text-primary text-base md:text-lg lg:text-xl font-bold'>Ask a Question</Text>
                <Text size="sm" color="dimmed" className="text-xs md:text-md lg:text-base">
                  Upload a question and MYAi will guide you step by step.
                </Text>
              </div>
            </Group>
          </Card>
          <Card shadow="sm" padding="md" radius="md">
            <Group>
              <div>
                <Text className='text-primary text-base md:text-lg lg:text-xl font-bold'>Targeted Learning</Text>
                <Text size="sm" color="dimmed" className="text-xs md:text-md lg:text-base">
                  Maximise your study time by focusing on questions you the need help in.
                </Text>
              </div>
            </Group>
          </Card>
          <CustomButton onClick={() => {
            window.open('https://dev.myaiconsult.com/', '_blank');
          }} variant="default" size='md' width='w-full md:w-2/4 my-10'>Try Now</CustomButton>
        </div>
      </div>
    </div>
  );
};

const ForParentsSection: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className="h-full bg-gradient-to-r from-orange-50 to-white flex items-center justify-center">
      <div className="w-full px-10 md:px-0 md:w-[85%] grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="flex flex-col justify-center">
          <Text color="dimmed" className="text-sm mb-2">For Parents</Text>
          <Text className="mb-4 text-lg md:text-xl lg:text-xxl font-bold">
            Help your child progress with personalised guidance
          </Text>
          <Text className="mb-6 text-sm md:text-lg lg:text-base">
            Using an encouraging and conversational tone, MYAi provides your child with guidance based on their learning needs.
          </Text>
          <List spacing='xl' size="sm" center icon={
            <ThemeIcon className='bg-blue-100' size={24} radius="xl">
              <Icon name='check' color='blue' size={'14'} />
            </ThemeIcon>
          }>
            <List.Item className="text-sm md:text-md lg:text-base">Support your child with the first-of-its-kind personalised learning system.</List.Item>
            <List.Item className="text-sm md:text-md lg:text-base">Monitor your child's progress through the chats.</List.Item>
            <List.Item className="text-sm md:text-md lg:text-base">Ensure your child is exposed to credible content in a safe space.</List.Item>
            <List.Item className="text-sm md:text-md lg:text-base">Obtain help from our friendly agents 24/7.</List.Item>
          </List>
          <CustomButton onClick={() => {
             window.open('https://dev.myaiconsult.com/', '_blank');
          }} variant="default" size='md' width='w-full md:w-2/4 mt-10'>
            Get Started
          </CustomButton>
        </div>
        <div className="hidden lg:flex items-center justify-center">
          <Image
            src="/landing/family.png"
            alt="Happy family using MYAi"
            className="max-h-[500px] max-w-[500px] object-contain"
          />
        </div>
      </div>
    </div>
  );
};

const TestimonialsSection: React.FC = () => {
  const { isMobile } = useResponsive()
  const testimonials = [
    {
      name: 'Shu Ting',
      avatar: '/landing/testimonial.png',
      rating: 5,
      subtitle: 'Parent of P4 Science Learner',
      comment: 'This solution REVOLUTIONIZES education! Now every child has access to high quality learning experience.',
    },
    {
      name: 'Jacob Lee',
      avatar: '/landing/testimonial2.png',
      rating: 4,
      subtitle: 'Parent of P3 Science Learner',
      comment: 'MYAi talks to me just like my science teacher. I feel like I can ask any questions and MYAi will guide me.',
    },
    {
      name: 'Sharadha',
      avatar: '/landing/testimonial3.png',
      rating: 5,
      subtitle: 'Parent of P4 Science Learner',
      comment: 'It helps me to know what my son is learning. My son learns how to use new technology to help him.  I hope we can do this for more subjects.',
    },
  ];

  return (
    <div className="h-full bg-gradient-to-r from-orange-50 to-white flex items-center justify-center py-10">
      <div className="w-full h-full md:h-3/4 overflow-hidden px-10 md:px-0 md:w-[85%]">
        <Title order={2} align="center" className="mb-4 md:mb-12 text-md md:text-xl lg:text-xxl">
          What Have They Been Saying About Us?
        </Title>
        <div className='grid overflow-y-auto grid-rows-3 md:grid-cols-3 gap-2 md:gap-4 items-center'>
          {testimonials.map((testimonial, index) => (
            <Card key={index} shadow={isMobile ? '' : 'md'} p={isMobile ? "xs" : 'lg'} radius="xl" className='h-40 md:h-[260px] flex flex-col justify-center'>
              {/* <Card.Section className='flex items-center justify-center'>
                <Avatar
                  className='ring-4 ring-gray-100 shadow-md'
                  src={testimonial.avatar}
                  size={120}
                  radius={120}
                  mt="md"
                />
              </Card.Section> */}
              <Group mt="md" position='apart' mb="xs">
                <Text className="font-bold text-base md:text-xl lg:text-xxl">
                  {testimonial.name}
                </Text>
                <Rating value={testimonial.rating} readOnly />

              </Group>

              <Text className="font-light text-sm md:text-md lg:text-lg">
                {testimonial.subtitle}
              </Text>
              <Text className="text-xs md:text-md lg:text-base" color="dimmed" mt="sm" style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                {testimonial.comment}
              </Text>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

const PricingSection: React.FC = () => {
  return (
    <div className="bg-primary h-full flex items-center justify-center py-10">
      <div className=" px-4 md:px-0 w-[85%]">
        <Text align="center" className="mb-8 font-bold text-white text-lg md:text-xl lg:text-2xl">
          Choose the Perfect Plan for Your Learning Journey
        </Text>
        <div className='flex flex-col md:flex-row justify-center items-center md:items-stretch gap-6'>
          <FreePlanCard />
          <PremiumPlanCard />
        </div>
      </div>
    </div>
  );
};

const FreePlanCard: React.FC = () => {
  const navigate = useNavigate()
  const features = [
    'Limited questions per day',
    'Text-based questions only',
    'Basic support',
  ];
  const missingFeatures = [
    'Image and document upload',
    'Access to question bank',
    '24/7 priority support',
  ];

  return (
    <div
      className="w-5/6 md:w-72 h-44 md:h-72 flex flex-col py-2 md:py-4 px-4 justify-between items-start md:items-center bg-gray-100 rounded-2xl"
    >
      <div className=''>
        <div className="flex flex-col items-start justify-center mb-2 md:mb-4">
          <Text className="text-xl md:text-2xl font-semibold text-gray-700">Free</Text>
          <Text className="hidden md:block text-sm md:text-base text-gray-600">Basic features for individual users</Text>
        </div>

        <List
          spacing="sm"
          size="sm"
          center
        >
          {missingFeatures.map((feature, index) => (
            <List.Item key={index} >
              <Text className="text-gray-500 text-xs md:text-base line-through">{feature}</Text>
            </List.Item>
          ))}
        </List>
      </div>
      <CustomButton
        variant="outline"
        size='sm'
        onClick={() => {
          window.open('https://dev.myaiconsult.com/', '_blank');
        }}
        width='w-full md:w-1/2'
      >
        Try for Free
      </CustomButton>
    </div>
  );
};

const PremiumPlanCard: React.FC = () => {
  const navigate = useNavigate()
  const { isMobile } = useResponsive()
  const features = [
    'Access to the chatbot interface',
    'Ask question using text or image',
    'Unlimited number of questions per day',
    'Access to the question bank'
  ];

  return (
    <div
      className="w-full md:w-[500px] h-72 md:h-[400px] flex flex-col py-4 px-4 justify-between bg-white rounded-2xl shadow-lg border-4 border-green-700"
    >
      <div className="flex flex-col justify-center mb-2 md:mb-4">
        <div className='flex flex-row items-center justify-between'>
          <Text className="text-xl md:text-xxl font-semibold text-primary">Premium</Text>
          <div className='flex flex-row items-center gap-2'>
            <Badge py={"sm"} className='bg-red-500 text-white self-end'>HOT</Badge>
          </div>

        </div>

        <div className='flex flex-row items-center gap-2'>
          <Badge py={"sm"} className='bg-green-700 sm:flex hidden text-white'>NOW</Badge>
          <Text className="text-base md:text-lg font-bold">
            SGD $51.3<span className="text-lg font-bold">/month</span>
          </Text>
          <Text className="text-xs md:text-md font-semibold line-through">
            SGD $88
          </Text>
        </div>
      </div>

      <List
        spacing={isMobile ? "xs" : "md"}
        size={isMobile ? "xs" : "md"}
        center
        icon={
          <ThemeIcon color='#29748F' size={isMobile ? 20 : 28} radius="xl">
            <Icon name='check' size={'18'} />
          </ThemeIcon>
        }

      >
        <Text className="hidden md:block text-sm md:text-base text-gray-600 mb-2">Advanced features for serious learners</Text>
        {features.map((feature, index) => (
          <List.Item key={index} className="text-sm md:text-base">
            {feature}
          </List.Item>
        ))}
      </List>

      <CustomButton
        variant="default"
        color="blue"
        size='sm'
        width='w-full'
        onClick={() => {
          window.open('https://dev.myaiconsult.com/', '_blank');
        }}
      >
        Get Started
      </CustomButton>
    </div>
  );
};

// ... rest of the file remains unchanged ...

const FAQSection: React.FC = () => {
  const faqs = [
    { question: "What is MYAi? How does it work?", answer: "MYAi is an intelligent tutoring system designed by a group of EdTech enthusiasts with strong content knowledge. For learners and families, MYAi offers encouraging and MOE-aligned guidance for students. It is ethically designed with safety and learning as top priority. MYAi is the only Generative AI powered tool that is available in Singapore. It covers the Primary Science subject at this beta stage. Your child can get personalised guidance on every question he asks. Unlike other AI tools such as ChatGPT, MYAi doesn't give learners the answers. Instead with limitless patience and well-known teaching methods, it guides learners towards the answer. Based on the needs of every learner, similar types of questions are proposed for students to master the concepts." },
    { question: "How do I get MYAi? Can anyone use it?", answer: "MYAi is a web-based platform that is accessible by all learners. Each learner will require a parent to create an account. Parent should sign up for one account for each child using so that the guidance given to each child is tailored to their specific level and learning needs." },
    { question: "How do I sign up for MYAi?", answer: `You may create an account with MYAi through the <insert link> to access the Premium Account for free for 7 days. After the 7 day free trial, users will get access to the Basic Account. Subscribe to the monthly or annual plans, to continue to use the Premium Account.`},
    { question: "What is the difference between the Premium and Basic Account?", answer: "In the Premium account, each parent can create multiple learner accounts based on their level. Learners will be able to upload images and documents to ask your question. Your questions will be used to retrieve other similar questions from the database to guide you in your learning. In the Basic account, each parent will only have access to one learner account. Learners can continue to ask questions using text only. There will be no suggested questions provided for learners." },
    { question: "How can I teach my child to use AI?", answer: "As EdTech enthusiasts, we see it as our responsibility to explore how AI can transform the future of education. We believe that AI has great potential to personalise learning for every child. It's our mission to give every child access to quality teaching, but we are also well-aware of the risks. MYAi is ethically designed with safety and learning as a top priority. Guardrails are in place to help your child focus on learning. Parents have full access to the chat history and will be able to flag messages to us when inappropriate interactions occur. At MYAi, we are parents too." },
  ];

  return (
    <div className="h-full flex flex-col justify-center px-4 md:px-8 lg:px-16 max-w-6xl mx-auto">
      <Title order={1} align="center" className="mb-4 text-lg md:text-xl lg:text-xxl">Frequently Asked Questions</Title>

      <div className='flex flex-col items-center justify-center mb-8'>
        <Text align="center" color="dimmed" className=" text-xs md:text-md lg:text-base">
          For any queries, reach out to us at support@myaiconsult.com.
        </Text>
        <div className='flex flex-row gap-2 mt-2 items-center justify-center'>
          <Text onClick={() => {
            window.open('https://wa.me/6597766164', '_blank');
          }} component='a' align="center" className=" cursor-pointer underline text-green-600 text-xs md:text-md lg:text-base">Whatsapp Us!</Text>
          <Icon name='whatsapp' color='green' size={'20'} />
        </div>
      </div>

      <div className="overflow-y-auto max-h-[60vh] md:max-h-[70vh] pr-2">
        <Accordion>
          {faqs.map((faq, index) => (
            index != 2 ? <Accordion.Item key={index} value={`item-${index}`}>
              <Accordion.Control className="text-xs md:text-md lg:text-base">
                {faq.question}
              </Accordion.Control>
              <Accordion.Panel className="text-xs md:text-md lg:text-base">{faq.answer}</Accordion.Panel>
            </Accordion.Item> : <Accordion.Item key={index} value={`item-${index}`}>
              <Accordion.Control className="text-xs md:text-md lg:text-base">
                {faq.question}
              </Accordion.Control>
              <Accordion.Panel className="text-xs md:text-md lg:text-base">
                <Text>You may create an account with MYAi through the <a className='underline cursor-pointer text-blue-500' href={'https://dev.myaiconsult.com/auth/signup'}>Sign up</a> to access the Premium Account for free for 7 days. After the 7 day free trial, users will get access to the Basic Account. Subscribe to the monthly or annual plans, to continue to use the Premium Account.</Text>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

const Footer: React.FC = () => {
  const {isMobile} = useResponsive()

  return (
    <footer className="bg-primary text-white py-8">
      <Container size="xl">
      <Group position="center" spacing="md" className="mb-2">
      <div className='flex flex-col justify-center items-center'>
        <Group position="center" spacing="md" className="mb-2">
          <ActionIcon className=' hover:bg-primary' onClick={() => {
             window.open('https://www.facebook.com/profile.php?id=61564846074866&sk=about', '_blank');
          }} size="lg" variant="subtle" color={"white"} >
            <Icon name='facebook' color='white' size={'28'} />
          </ActionIcon>
          <ActionIcon className=' hover:bg-primary' onClick={() => {
             window.open('https://www.instagram.com/my_aiconsult/', '_blank');
          }} size="lg" variant="subtle" color={"white"} >
            <Icon name='instagram' color='white' size={'28'} />
          </ActionIcon>
          {/* <ActionIcon onClick={() => {
             window.open('https://www.instagram.com/my_aiconsult/', '_blank');
          }} size="lg" variant="subtle" color={"white"} >
            <Icon name='twitter' color='white' size={'28'} />
          </ActionIcon> */}
          {!isMobile && <Icon name='pin' className=' ml-4' color='white' size={'28'} />}
          <div className="flex flex-row items-center mb-2 w-40">
          {isMobile && <Icon name='pin' className=' ml-4' color='white' size={'28'} />}
          <Text align="center" className="text-xs text-white md:text-md lg:text-base">
            10 Anson Road,
            #22-02,
            International Plaza,
            Singapore (079903)
          </Text>
          
          </div>
        </Group>
        
       
          <Text align="center" className="text-xs text-white md:text-md lg:text-base">
            MYAi Consult Pte Ltd (UEN: 202435444M). All rights reserved.
          </Text>

        </div>

          </Group>
      </Container>
    </footer>
  );
};


export default LandingPage;